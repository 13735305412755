.JobExperience-input-item{
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
   }
.JobExperience-section {
    border: 1pz solid red ;
   }
   
   
   .JobExperience-section__items{
       display: flex;
       flex-direction: column;
       gap: 20px;
       padding: 10px 0px ;
       border-radius: 5px;
     
      
   }
   .JobExperience-item__delete-button{
       position: absolute;
       top: 10px;
       right: 10px;
   }
   .JobExperience-item {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px 10px ;
    height: 100%;
    border-radius: 5px;
    padding-top: 40px;
    position: relative;
    border: 1px solid rgba(0, 0, 0, 0.1);
   
   }
   
   .JobExperience-item__dates-container{
       display: flex;
       flex-direction: column;
       gap: 10px;
   }
   .JobExperience-item__description{
width: 100% !important;
    resize: none;
    min-height: 80px;
   }
   