.footer-container{
    width: 100%;
    margin-top: auto;
    display : flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 80px;
    background-color: #000000cc;
    

}

.footer-menu{

display: flex;
width: 100%;
justify-content: space-evenly;
align-items: center;
max-width: 500px;
margin: 0px 20px;
margin-bottom: 50px;

}


.footer-sub-container{
  display: flex;
  flex-direction: column;
 padding: 0px 10px;
width: 100%;
}
.footer-button{
  width: 100%;
  margin: 25px 0px 60px 0px;
  display: flex;
  align-items: center;
  justify-content: center;

}

.button-hover:hover{
  opacity: 0.4;
  cursor: pointer;
}


.hire-text{

display: flex;
flex-direction: column;
justify-content: center;
  align-items: center;

}
.txt-pos{
  text-align: center;
}



@media (min-width: 750px) {
 .txt-pos{
  text-align: left;
 }
 .footer-button{
  justify-content: right;
 }
.hire-text{
padding: 0px 25px;
 display: flex;
 flex-direction: row;
 align-items: center;
 justify-content: center;
 width: 100%;
}
   
  .footer-sub-container{
    flex-direction: row;
 
    max-width: 1000px;
    padding: 0px;
 
  }


  .footer-line-break{
    border: solid 1px rgba(256, 256, 256, 0.2);
    width: 100%;
    border-radius: 10px;
    margin: 30px 0px 50px 0px;
    max-width: 1000px;


  }


  }