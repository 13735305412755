:root{
    --laburo-green : #15BC7F;
}


/* ... existing code ... */



/* ... existing code ... */
.bl-screen-small{
    display: none;
}
.bl-container{


min-height: 100vh;
width: 100%;

}
.bl-content{
/* padding: 0px 20px; */
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
padding-top: 50px;
padding-bottom: 50px;

}

.bl-box{

max-width: 1300px;
display: flex;
flex-direction: column;
gap: 100px;

}
.bl-hero{
width: 100%;
gap: 15px;
display: flex;
justify-content: center;

padding: 0px 20px;
}
.bl-hero-text{
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    gap: 50px;
    max-width: 700px;
}

.bl-hero-text-title{
font-size: 50px;
}
.bl-title-text-size{
font-size: 50px;
margin-bottom: 20px;
}


.bl-hero-text-subtitle{
    max-width: 550px;
    font-size: 25px;
    color: rgba(0, 0, 0, 0.6);
}
.bl-hero-button{
    background-color: var(--laburo-green);
    display: flex;
    justify-content: center;
    color: white;font-size: 18px;
   width: 250px;
   padding: 20px;
   border-radius: 10px;
   font-size: 20px;
}


.bl-hero-img{
    width: 500px;
    height: auto;
    border-radius: 50%;
}

.bl-steps{
    display: flex;
    justify-content: center;
    flex-direction: column;

    align-items: center;
  gap: 20px;
  padding: 0px 20px;
}

.bl-steps-container{

    display: flex;
    gap: 20px;
    width: 100%;
}

.bl-stepscards-container{
display: flex;
flex-direction: column;
justify-content: center;
gap: 20px;
width: 60%;
}

.bl-steps-screen{
    width: 40%;
    display: flex;
    justify-content: center;
}

.bl-steps-card{
    display: flex;

    width: 100%;
    border: 1px solid rgba(0, 0, 0, 1);

    border-radius: 10px;
    flex-direction: column;
    padding: 10px 10px;
    padding-bottom: 20px;
    max-width: 650px;
}
.bl-steps-card {
    transition: box-shadow 0.3s ease-in-out;
}

.bl-steps-card:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}



/* Step Card  */

.step-card-number {
font-size: 25px;
color: var(--laburo-green);


}
.step-card-title{
font-size: 30px;


}
.step-card-subtitle{
font-size: 20px;
color: rgba(0,0,0,0.6);
}


.bl-screen-carousel{
    border-radius: 25px;
    display: flex;
   
  border: 3px solid rgba(0, 0, 0, 0.7);

}

.bl-demo{
   
width: 100%;


}
.bl-demo-bg{
background-color: rgba(0, 0, 0, 0.05);


width: 100%;
padding-bottom: 90px;
padding-top: 20px;
border-radius: 10px;
display: flex;
flex-direction: column;
gap: 50px;
}


.bl-demo-title{
font-size: 50px;
display: flex;
width: 100%;
justify-content: center;
padding-top: 50px;
}

.bl-demo-subtitle{
    font-size: 20px;
    display: flex;
    width: 100%;
    height: auto;
    justify-content: center;
    margin-top: 10px;
    color: rgba(0, 0, 0, 0.5);
}

.bl-demo-video{
    display: flex;
    justify-content: center;
    padding: 0px 50px;
}
 .bl-demo-video video{
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2), 0 6px 6px rgba(0, 0, 0, 0.1);
 }



/* pricing */

.bl-pricing {
display: flex;

flex-direction: column;
align-items: center;
gap: 50px;
padding: 0px 20px;

}
.bl-pricing-title{

    font-size: 50px;
text-align: center;
}



.bl-pricing-subtitle{font-size: 20px;
text-align: center;
color: rgba(0, 0, 0, 0.6);

}


.bl-price-cards{
    display: flex;
    justify-content: space-evenly;

    width: 100%;
    gap: 10px;
    max-width: 1000px;
 
}

.bl-price-card{
    border: 1px solid rgba(0, 0, 0, 0.2);
    height: 400px;
    width: 100%;
    max-width: 300px;
    padding: 10px 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    position: relative;
    transition: box-shadow 0.3s ease-in-out, border-color 0.3s ease-in-out;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.bl-price-card:hover {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    border-color: var(--laburo-green);
}


.bl-price-card-pointers{
padding-left: 15px;    
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.bl-price-card-title{
    margin-top: 20px;
    font-size: 25px;
    color: var(--laburo-green);
}

.bl-price-card-description{
    font-size: 18px;
     height: 75px;
}
.bl-price-card-price{

position: absolute;
bottom: 10px;
right   :20px;
font-size: 30px;

}

.bl-price-card-img{
position: absolute;
width: 50px;
height: auto;
right: 10px;
top: 10px;
}

/* free button */

.bl-free-post{margin-top: 50px;
    margin-bottom: 50px;
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
padding: 0px 20px;

}
.bl-free-post-title{
    font-size: 40px;
    margin-bottom: 10px;
}
.bl-free-post-subtitle{
font-size: 20px;
color: rgba(0, 0, 0, 0.6);
text-align: center;

}
.bl-free-post-button{
    background-color: var(--laburo-green);
    padding: 20px 30px;
    border-radius: 50px;
    text-align: center;
    color: white;
width: 100%;
    max-width: 300px;
    margin: 20px;
    font-size: 20px;

}



@media (max-width: 873px) {
    .bl-content{
        padding-top: 10px;
    }
    .bl-hero{
        display: flex;
        flex-direction: column-reverse;
        align-items: center;
    }
.bl-hero-text-title{
    font-size: 40px;
    text-align: center;
}
.bl-demo-bg{
    border-radius: 0px;
    
}

.bl-title-text-size{
    font-size: 40px;
}
.bl-box{
gap: 70px;  
}
.bl-demo-title{
    font-size: 40px;
    text-align: center;
}
.bl-demo-subtitle{
    font-size: 18px;
    text-align: center;
}

.bl-pricing-title{
    font-size: 30px;
}
.bl-pricing-subtitle{
    font-size: 18px;


}

.bl-free-post-title{
    font-size: 30px;
}   

.bl-free-post-subtitle{
font-size: 18px;

}

.bl-hero-text-subtitle{
   display: flex;
font-size: 18px;    

max-width: none;
justify-content: center;
text-align: center;
}
  .bl-screen-big{
    display: none;
  }
  .bl-screen-small{
    display: flex;
  }

  .bl-hero-button{
   width: 80%;
   max-width: 300px;
  }
    .bl-hero-img{
        border-radius: 5%;
        width: 100%;
        max-width: 500px;
        height: auto;
    }

    .bl-demo-video{
        padding: 0px 10px;

    }
    .bl-demo-video video{
        border-radius: 5px;
     }
    
    

    .bl-hero-text{
    gap: 25px;
  width: 100%;

display: flex;
    }
   
    .bl-steps-screen{
        display: none;
    }
   
 .bl-steps-container{
 width: 100%;
 justify-content: center;
 }
    .bl-stepscards-container{
        width: 100%;
        align-items: center;
     
    }
    .bl-price-cards{
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
    .bl-price-card{
        width: 100%;
        max-width: none;
        height : 100%;
    
    }
    .bl-price-card-description{
    height: 50px;

    
    }
    .bl-price-card-pointers{
    margin-bottom: 50px;
    
    }
   .step-card-title{
   font-size: 25px;}
   
 }

/*  */