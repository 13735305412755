:root{
    --laburo-green : #15BC7F;
}

.subbar-container{

    width:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(4px);
    margin-left: 20px;
}

.subbar-box{
    display: flex;
    width: 100%;
    gap: 30px;
    margin: 0px 20px;
    max-width: 1000px;
   
    
}


.subbar-btn{
    color: black;
    background-color: transparent;
    font-size: 18px;
}
.subbar-btn:hover{
    cursor:pointer;
}

.subbar-btn-selected{
    text-decoration: underline;
}

.scrollbar-hide {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;     /* Firefox */
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;             /* Chrome, Safari and Opera */
}