:root{
    --laburo-green : rgb(21, 188, 127);
    --nav-height: 75px;
}   



.bg-laburo-green {
    background-color:var(--laburo-green);
}
.laburo-green{
    color: var(--laburo-green);
}

.laburo-logo-txt{
    font-size: 32px;
  

}

.nav-container{
   display: flex;
   height: var(--nav-height);
   width: 100%;
   padding: 0 10px;

   justify-content: space-between;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
/* 
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2); */

   
 
}

.nav-box{

display: flex;
align-items: center;
justify-content: space-between;
width: 100%;


}

.nav-inner-container{
        display: flex;
    align-items: center;
    justify-content: right;


   
}
.navlink-box{
  display: flex;

  justify-content: left;
  align-items: center;
  width: 100%;

  font-size: 20px;
  
} 

.navlink-item{

    transform: translateY(4px);
}




.post-job-btn{
    border-radius: 50px;
   width: 100px;
   height: 43px;
   text-align: center;
   color: white;
   padding: 2px;
   font-size: 14px;
}


.nav-menu{
   top: 0;
   left: -300px;
    position: fixed;
    background-color: rgba(256 , 256,256, 0.95);
    height: 100vh;
    width: 300px;
    z-index: 2;
    /* transition: left 0.3s ease-out;  */
    transition: left 0.3s ease-out, opacity 0.3s ease-out; 

}

.nav-menu.open{
left: 0px;
background-color: rgba(256 , 256,256, 0.95);
z-index: 10;
opacity: 1;
}

.nav-menu-items{
    font-size: 22px;
    line-height: 2;
    margin-left: 20px;
    margin-top: 100px;
   
}
.nav-menu-block{
    top: 0;
    left: 0;
     position: fixed;
   
     height: 100vh;
     width: 100%;
     z-index: 9;
  
    
    
}

a{
    text-decoration: none;
    color: inherit;
}



@media(min-width:750px){
    
    .nav-container{
        padding: 0 20px;
    }
    .nav-text{
        color: white;
    }

    .laburo-logo-txt{
        font-size: 40px;
       
    
    }
 
    .nav-inner-container{
        justify-content: flex-end;
        transform: translateY(4px);
    }

    .nav-text-desk-search:hover{
        /* color: rgba(256,256,256,0.4); */
        opacity: 0.4;
    }
    
.nav-txt-desk-job{
    background-color: rgba(21, 188, 127,0.8);
    padding: 10px 20px;
    border-radius: 30px;
   
}

.nav-txt-desk-job:hover{
    background-color: rgba(21, 188, 127,0.4);
    color: rgba(256,256,256,0.4);
}


    

  }


  .person-icon{
   
    color: white;
  }


