
.search-jobs-btn-container {
   
display: flex;
align-items: center;
position: relative;
width : 200px;
height: 55px;
background-color: rgba(217, 217, 217, 0.8);
border-radius: 10px;
box-shadow: 1px;
}


.search-icon {
right: 10px;
position: absolute;
z-index: 2;

}

.search-jobs-btn-container:hover{
    background-color: rgba(217, 217, 217, 0.4);

}

@media(min-width:750px){

   
    


}