:root{
    --laburo-green : #15BC7F;
}

.positions-container{
padding: 0px 10px;
padding-top: 25px;
padding-bottom: 25px;

height: 100%;
width: 100%;

display: flex;
justify-content: center;


}

.posts-available-container{

    display: flex;
    justify-content: right;
    padding-right: 25px;
    padding-top: 10px;
    
}


@media (max-width: 750px) {
  .positions-container{
    padding-top: 10px;

  }
}


.positions-box{

width: 100%;
max-width: 1000px;




}

.positions-box-filters{
    display: flex;
    width: 100%;
justify-content: right;

}


.positions-box-table{
margin-top: 15px;
display: flex;
flex-direction: column;
gap: 10px;

}

.positions-box-table-row{

display: flex;
padding: 10px 20px;
height: 70px;
gap: 10px;
border: 2px solid rgba(0, 0, 0, 0.2);
border-radius: 10px;
align-items: center;
justify-content: space-evenly;
}

.pbtc-fr {
 
    overflow: hidden;
    /* text-align: center; */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    align-items: center;
    justify-content: center;
    white-space: normal;
    text-overflow: ellipsis;
}
.positions-box-table-cell{
    width: 100%;
  
    }
  
  




@media(max-width:750px){

    .positions-box-table-row{
        padding:10px 10px;
        height: 90px;
        gap: 3px;
    }
   
   
}






.btc-title{
    display: flex;
    height: 43px;
    align-items: center;
}
.btc-edit{
    
   text-align: center;
}
.btc-edit:hover{cursor: pointer;}

.job-edit-popup-container{

position: fixed;
display: flex;
/* padding: 0px 10px; */
top: 0;
left: 0;

align-items: center;
width: 100%;
height: 100vh;

z-index: 1000;
display: flex;
align-items: center;
justify-content: center;
background-color: rgba(0, 0, 0, 0.5);


}


.job-edit-popup-box{
   top:0;
    width: 100%;
 background-color: white;
    /* border: 1px solid rgba(0, 0, 0, 0.2); */

 max-width: 1000px;
   
    /* max-height: 600px; */
   
    padding: 30px 20px;
  
    position: fixed;
    /* border: 1px solid red; */
    height: 100%;
  
    overflow-y: auto;
  
    
}
.job-edit-close-btn{
    margin-right: 10px;
    margin-top: 10px;
    position: absolute;
    right: 0px;
    top: 0px;
}

.add-position-btn{
    background-color: var(--laburo-green);
    padding: 10px 20px ;
    border-radius: 10px;
    color: white;
}

.add-position-btn-small{
    background-color: var(--laburo-green);
    padding: 5px 10px ;
    border-radius: 10px;
    color: white;
}

/* button color  */
/* .css-jsexje-MuiSwitch-thumb {
    background-color: var(--laburo-green)!important;
}
.css-byenzh-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track{
    background-color: var(--laburo-green)!important;
} */

.confirm-pop-up-container{
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100000;
    padding: 0px 10px;
    
}

.confirm-pop-up-box{
    background-color: white;
    height: 200px;
    width: 100%;
    max-width: 400px;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

  

}


.buttons-container{
    display: flex;
    gap: 20px;
}

.cancel-button{
    background-color: rgba(0, 0, 0, 0.3);
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
}

.confirm-button{
    background-color: var(--laburo-green);
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
}


@media (min-width: 750px) {
    .job-edit-popup-box{
        /* max-height: 700px; */
        /* border-radius: 10px; */
        padding: 30px 20px;
        position: relative;
}
}


.subscription-type {
    color: black;
    text-shadow: none;
}

.subscription-type.free {
    color: var(--laburo-green);
}

.subscription-type.starter {
    color: #2196F3;

}

.subscription-type.growth {
    color: #9C27B0;
   
}

.subscription-type.pro {
    background: linear-gradient(45deg, #FF9800, #F44336);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  
}


.add-pos-info-container{
    padding-top: 20px;
    
}

.add-pos-info-container-box{
font-size: 18px;
margin-bottom: 30px;

}

