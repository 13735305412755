.nws-container{

 width: 100%;
 height: 100vh;
 top: 0;
 left: 0;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10000000;
background-color: rgba(256,256,256,0.6);
padding: 0px 10px;
}

.nws-box{

background-color: white;
width: 700px;
box-shadow: 0px 1px 10px rgba(0,0,0,0.2);
border-radius: 15px;
padding: 30px 30px;
position: relative;


}

.nws-title{

font-size: 35px;



}

.nws-content{

font-size: 20px;



}

.nws-input-box-container{
    margin-top: 25px;
    margin-bottom: 10px;
 width: 100%;
 height: 40px;
display: flex;
gap: 10px;
justify-content: center;
}
.nws-input-box-container input{
border: 1px solid rgba(0,0,0,0.2);
 border-radius: 5px;
    width: 100%;
    padding: 0px 10px;
    font-size: 16px;
}

.nws-submit-btn{
    color:white;
    padding: 10px 10px;
    border-radius: 5px;
    font-size: 18px;
    display: flex;
    align-items: center;


}

.nws-disclaimer{

    font-size: 12px;
    opacity: 0.4;


}


.nws-close{
    position: absolute;
    top: 8px;
    right:10px;


}


@media (max-width: 750px){



.nws-content{
    margin-top: 10px;
}

.nws-input-box-container{
height: 50px;

}
.nws-box{
    padding-left: 15px;
    padding-right: 15px;
}

}
