.jsb-container{
margin-top: 20px;

border-radius: 10px;
height: 60px;
display: flex;
justify-content: space-between;
align-items: center;
width: 80%;
border: 2px solid rgba(0, 0, 0, 0.4);
box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.4);

padding-left: 10px;
padding-right: 8px;

}

.jsb-line-search-separator{

border: 1px solid rgba(0, 0, 0, 0.4);
height: 100%;
border-radius: 50px;
width: 1px;    
margin-right: 10px;

}
.jsb-input-field {
    width: 100%;
    display: flex;
    align-items: center;
}

.jsb-search-btn{
    width: 90px;
   
    height : 75%;
    border-radius: 7px;

    font-size: 16px;
    
}
.jsb-search-btn:hover{
    cursor: pointer;
}


.jsb-mobile{
    padding: 10px 10px;
    height: 100%;
border: 1px solid rgba(0, 0, 0, 0.2);
padding: 20px 10px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
    width: 100%;
   flex-direction: column;

}
.jsb-mobile-input-bars{
margin-bottom: 10px;

display: flex;
gap: 10px;
flex-direction: column;
justify-content: space-evenly;
   height: 100%;
   width: 100%;

}

.jsb-mobile-input-bars input {
    font-size: 16px;
}

.jsb-mobile-btn{
    height: 50px ;
    width: 100%;


}
.jsb-input-mobile {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 5px 10px;
  border-radius: 50px;   
}