
.applicant-sidebar-container{

display: flex;
justify-content: center;
width: 100%;



overflow: hidden;
padding-bottom: 30px;

}

.applicant-sidebar-box{
    max-width: 1000px;
    display: flex;
    justify-content: center;
    width: 100%;
}

.applicant-sidebar-left-box{

  width: 50%;
  padding-right: 10px;
   
  
  overflow: auto;

}


.applicant-sidebar-right-box{
    width: 100%;
    border: 1px solid rgba(0, 0,0, 0.2);
   border-radius: 10px;
    overflow: auto;
    overflow-x: hidden;
    
}


.applicant-info-container{
 display: flex;
 align-items: center;
 width: 100%;
 position: relative;


}

.applicant-info-box{
display: flex;
flex-direction: column;
gap: 15px;
padding: 20px 30px;
width: 100%;

}
.pdf-viewer {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Arial, sans-serif;

  }
  
  .navigation {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  
  .navigation button {
    margin: 0 5px;
    padding: 5px 10px;
    font-size: 16px;
    cursor: pointer;
  }
  .download-btn{

    padding: 10px 20px;
    border-radius: 5px;
    background-color: var(--laburo-green);
    color: white;
    font-size: 18px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    
    gap: 10px;
  }
  .download-btn:hover{
    cursor: pointer;
  }
  .navigation button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  
  .error {
    color: red;
    font-weight: bold;
  }

  .react-pdf__Page__canvas {
    width: 100% !important;
    height: 100% !important;
   
}
  
.applicant-dropdown{


display: flex;
margin-top: 15px;



}
.applicant-dropdown-box{
    position: relative;
  
}

.applicant-dropdown-header{
border: 1px solid rgba(0, 0, 0, 0.2);

border-radius: 5px;
padding: 10px;
min-width: 250px;
display: flex;
justify-content: space-between;
align-items: center;
position: relative;

}
.applicant-dropdown-arrow{

    font-size: 12px;
    display: flex;
    align-items: center;

}

.applicant-dropdown-list{
position: absolute;
top: 100%;
left: 0;
background-color: white;
display: flex;
flex-direction: column;
gap: 10px;
width: 100%;
border: 1px solid rgba(0, 0, 0, 0.2);
border-top: none;
border-radius: 0px 0px 5px 5px;
padding: 10px;
max-height: 100px;
overflow: auto;
z-index: 1;

}
.applicant-dropdown-list-item:hover{
    text-decoration: underline;
    cursor: pointer;
}

.adl-open{
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}


@media (max-width: 750px) {
  .applicant-sidebar-left-box{
    width: 100%;
    padding-right: 0;
    height: 100%;
    max-height: none;
  }

}
/* -------- */
.location-applicant-filter-container{
 width: 100%;
display: flex;
align-items: flex-end;

justify-content: right;
padding: 10px ;

}

.location-applicant-filter-dropdown{
  position: relative;
  width: 200px;
 


}

.location-applicant-filter-box{
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;
  padding: 10px 10px;
  justify-content: space-between;
border-radius: 5px;
align-items: center;


  
}


.location-applicant-filter-dropdown-options{
  padding: 10px ;
position: absolute;
border: 1px solid rgba(0, 0, 0, 0.2);
border-top: none;
left:0;
right: 0;
background-color: white;
z-index: 1;
cursor: pointer;
display: flex;
flex-direction: column;
gap: 10px;
border-bottom-left-radius: 5px;
border-bottom-right-radius: 5px;
}



.location-dropdown-open{
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;

}


.location-applicant-filter-dropdown-options div:hover{
  text-decoration: underline;
}


/*  when selected underline */

.location-dropdown-option-selected {

  text-decoration: underline;

}




/* applicant edit popup  */
.ae-popup-container{
  position: absolute;
  top:0;
  left:0;
  background-color: rgba(0, 0, 0, 0.2);
  height: 100%;
  width: 100%;
  z-index: 100;
  
  
}

.ae-popup-box{
  background-color: white;
  height: 80%;
}

/* dropdown */

.ae-popup-dropdown-container{
  width: 100%;
  position: relative;
  font-size: 16px;

  
  
}
.ae-popup-dropdown{

border-radius: 5px;
border: 1px solid rgba(0, 0, 0, 0.2);
font-size: 18px;
padding: 15px;

}

.ae-popup-dropdown-options{

position: absolute;
  border:1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0px 0px 5px 5px;
 width: 100%;
  border-top: none;
  display: flex;
  flex-direction: column;
  gap:10px;
  padding :15px;
  cursor: pointer;
  font-size: 18px;
  background-color: white;
  z-index: 100;
}







.ae-popup-dropdown-open{
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}


/* applicant state dropdown */

.asdd-container{

  width: 100%;
  max-width: 200px;
  /* margin-bottom: 15px; */
  position: relative;
  
}

.asdd-box-header{
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 8px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
min-width: 150px;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.asdd-box-header-open {

  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.asdd-box{
display: flex;
flex-direction: column;
gap: 10px;
padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
border-top: none;
position: absolute;
left: 0;
top: 100%;
right: 0;
width: 100%;
background-color: white;

}

.asdd-box-item:hover{
  opacity: 0.8;
  cursor: pointer;
}

.filter-dropdown-container{
 
  
  display: flex;
  justify-content: center;
  align-items: flex-end;
  
  width: 100%;



}
.filter-dropdown:hover{
  cursor: pointer;
}

.filter-dropdown{
  
 width: 100%;
 
  position: relative;


}


.filter-dropdown-box{
  position: absolute;
  
left: 0;
top: 100%;
padding:10px 10px;
border-bottom-left-radius: 5px;
border-bottom-right-radius: 5px;
border: 1px solid rgba(0, 0, 0, 0.1);
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
background-color: white;
border-top: none;
width: 100%;
z-index: 100;
display: flex;
flex-direction: column;
gap: 5px;
}
.filter-dropdown-header{
  
  height: 56px !important;
  

}



@media (max-width: 750px) {
  .filter-dropdown-container{
    width: 100%;
    padding: 0;
    font-size: 18px;
  }
  .filter-dropdown{
   width: 100% !important;

  }
  .filter-dropdown-header{
    width: 100% !important;
    height: 56px !important;
    font-size: 18px !important;

  }
  .asdd-box-item:hover{
    opacity: 1;

  }
}


.applicant-section {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 20px 15px;
  padding-bottom: 25px;

  margin-bottom: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.applicant-info-box {
  padding: 25px 20px;
  height: 100%;
  overflow-y: auto;
}

/* Update existing education and work experience items to match */
.education-item,
.work-experience-item {

  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  margin-bottom: 10px;
}

.applicant-section .title {
  margin-bottom: 15px;

}


