.education-section {
    border: 1pz solid red ;
   }
   .education-input-item{
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
   }
   .education-section__items{
       display: flex;
       flex-direction: column;
       gap: 10px;
       padding: 10px 0px ;
      
   }
   .education-item__delete-button{
       position: absolute;
       top: 10px;
       right: 10px;
   }
   .education-item {
       display: flex;
       flex-direction: column;
       gap: 10px;
       padding: 20px 10px ;
       height: 100%;
       border-radius: 5px;
       padding-top: 40px;
       position: relative;
       border: 1px solid rgba(0, 0, 0, 0.1);
   }
   .education-item__dates-container{
       display: flex;
       flex-direction: column;
       gap: 10px;
   }