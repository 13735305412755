.compcar-container{
width: 100%;


display: flex;
justify-content: center;
flex-direction: column
;
align-items: center;
}

.compcar-box{
    height: 200px;
   width: 100%;
   min-width: 300px;
    display: flex;
   gap: 5%;
   justify-content: center;
    align-items: center;
    background-color: rgba(256, 256, 256, 0.1);
    border-radius: 20px;

}


.compcar-element img{
   width:100px;
 
   border-radius: 10px;
}

.compcar-title{
    font-size: 30px;
    margin-bottom: 20px;
}

@media(min-width:500px){

    .compcar-box{
        gap: 10%;
        width: 100%;
        max-width: 600px;
    }
    .compcar-element img{
        width:110px;
 
        border-radius: 10px;
     }
    
     .compcar-title{
        font-size: 36px;
        margin-bottom: 20px;
    }

}

@media(min-width:1000px){



.compcar-box{
    height: 250px;
   width: 80%;
   
   min-width: 800px;
    display: flex;
   gap: 100px;
   justify-content: center;
    align-items: center;
    background-color: rgba(256, 256, 256, 0.1);
    border-radius: 20px;

}

.compcar-element img{
    width:140px;

    border-radius: 10px;
 }


 .compcar-title{
    font-size: 42px;
    margin-bottom: 20px;
}
}







