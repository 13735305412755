.uploader-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: none;
    border-radius: 8px;
    padding: 15px 25px;
    font-size: 18px;
    color: white;
    background-color: var(--laburo-green);
    transition: all 0.2s ease-in-out;
    font-weight: 500;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.uploader-container:hover{
    opacity: 0.9;
    cursor: pointer;
   
}

.uploader-container:disabled {
    background-color: #e0e0e0;
    cursor: not-allowed;
    transform: none;
    opacity: 0.7;
}

.erase-doc-button{
    margin-left: 5px;
   color :red;
   background-color: transparent;
}

.erase-doc-button:hover{
    cursor: pointer;
    opacity: 0.4;

}

.resume-loading-container {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 15px 25px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  width: 100%;
  text-align: center;
  min-height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.resume-loading-message {
  font-size: 16px;
  color: #333;
}

.resume-fade-in {
  opacity: 1;
  transition: opacity 0.5s ease-in;
}

.resume-fade-out {
  opacity: 0;
  transition: opacity 0.5s ease-out;
}

@keyframes shimmer {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}

.resume-loading-container {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.8) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  background-size: 1000px 100%;
  animation: shimmer 2s infinite linear;
}

.resume-loading-animation {
    animation: fadeInOut 2s ease-in-out infinite;
  }
  
  @keyframes fadeInOut {
    0% {
      opacity: 0.4;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.4;
    }
  }