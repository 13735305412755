:root{
    --laburo-green : #15BC7F;
}




.job-page-container{

padding: 0px 8px;
width: 100%;
max-width: 1000px;


}
.search-container{
    
    
    padding: 5px 15px;

    width: 100%;
    height: 220px;
  
    background-color: rgba(80, 80, 80, 0.1);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  
}

.search-pill{
    border: 1px solid rgba(0, 0, 0, 0.2);
    height: 44px;
    width: 100%;
    background-color: white;
    margin-bottom: 10px;
    border-radius: 5px;

}
.search-pill-input{

    width: 100%;
    height: 100%;
    border-radius: 10px;
    border: none;
    padding-left: 10px;
    padding-right: 20px;
    user-select: none;
    outline: none;
    font-size: 16px;
    
}


.search-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    height: 55px;
    width: 100%;
    transition: background-color 0.1s; 
        color:white;
        font-size: 20px;
    
}

/* .search-btn:hover{
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
   
    background-color: rgba(21, 188, 127,0.9);
} */



.order-dropdown{

    padding: 5px 10px;
    border-radius: 10px;
    background-color: rgba(80, 80, 80, 0.3);

}


/* job posting */

.job-post-container {
    
    width: 100%;
    padding: 5px 10px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;
    border-radius: 10px;
    margin-bottom: 10px;
   
    
}

.job-post-container:hover {
    cursor: pointer;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
}




.job-post-img-container{
    height: 100%; 
    width: 90px;
    padding-left: 20px;
    padding-top: 20px;

  
}

.job-post-img{
    border-radius: 50%;
    height: 50px;
    width: 50px;
}



.job-post-txt{
padding: 10px 0px;
align-items: baseline;

overflow: hidden;

}

.job-post-txt-col1{
    padding-left: 10px;

    display: flex;
    flex-direction: column;
    gap:10px;
    line-height: 1.5;

}



.job-post-position{
     /* white-space: nowrap;   */
    overflow:auto;
  
    padding-right: 10px;
    margin-right: 10px;
   
    
}
.job-post-company{
    overflow: hidden;
    
   margin-right: 5px;
    
    overflow: none;

}
.job-post-location{
    overflow: hidden;
    height: 24px;
    

}
.job-post-salary{
 background-color: rgba(0, 0, 0, 0.1);
 padding: 2px 10px ;
 font-size: 14px;
 border-radius: 3px;
}

.filters-menu{
    align-items: baseline;
    margin: 10px 0px 5px 0px;
}


/* job post flags */

.flag-container{
 
    

    display: flex;
    gap: 5px;
    font-size: 14px;
}

.info-flag{
   background-color:  rgb(21, 188, 127,0.4);
   border-radius: 3px;
   padding: 2px 4px;
}


.flag-purple{
    background-color: rgba(158, 0, 255, 0.3);
}

.flag-orange{
    background-color: rgb(255, 107, 0,0.3);
}
.flag-blue{
    background-color: rgb(21, 148, 188,0.3);
}
.flag-turquoise{

  background-color: rgb(64, 224, 208,0.3);

}
.flag-red{
    background-color: rgba(255, 0, 0, 0.322);
}

.flag-new{
    background-color:rgba(245, 0, 0, 0.3);
}

.flag-




.days-ago{
    color: rgba(0, 0, 0, 0.4);
    gap: 3px;
}





.job-des-bg{

    background-color: rgba(80, 80, 80, 0.1) ;
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    z-index: -1;
}

.job-des-content{
    
    margin-bottom: 50px;
    width: 100%;
    padding:  10px 10px 0px 10px;
   display: flex;
   flex-direction: column;
   align-items: center;
    

    
}


.job-des-content-apply{
   /* height: 500px; */
    max-width: 1000px;
    padding:  10px 10px 0px 10px;
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 100%;
   margin-bottom: 30px;

   
    

    
}


.job-des-img{

    background-color: var(--laburo-green);
    width: 44px;
    height: 44px;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 50px;
}

.aplicar-btn{
    height:60px;
    align-items: center;
    display: flex;
    justify-content: center;
 color: black;
    background-color: var(--laburo-green);
    border-radius: 10px;
    width: 50%;
    max-width: 400px;
    font-size: 18px;

}

/* description */

.job-des-input-pill {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    border: none;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 20px;
    user-select: none;
    outline: none;
    font-size: 16px;
   
    
}

.job-des-input{
    border: 2px solid black;
    height: 200px;
    width: 100%;
    background-color: white;
    margin-bottom: 10px;
    border-radius: 10px;
}

button{
    border:none;
}
form{
    width: 100%;
}



      /* for desktop */
      .whatsapp_float {
          position: fixed;
          width: 55px;
          height: 55px;
          bottom: 40px;
          right: 30px;
          background-color: #25d366;
          color: #FFF;
          border-radius: 50px;
          text-align: center;
          font-size: 35px;
          box-shadow: 2px 2px 3px #999;
          z-index: 100;
      }

      .whatsapp-icon {
        margin-top: 11px;
        margin-left: 2px;
      }

      /* for mobile */
      @media screen and (max-width: 767px) {

        
          .whatsapp-icon {
              margin-top: 11px;
              margin-left: 2px;
          }

          .whatsapp_float {
            position: fixed;
            
            background-color: #25d366;
            color: #FFF;
            border-radius: 50px;
            text-align: center;
            font-size: 30px;
            box-shadow: 2px 2px 3px #999;
            z-index: 100;
              width: 55px;
              height: 55px;
              bottom: 40px;
              right: 20px;
              font-size: 35px;
          }
      }



      .page-active{
        color: var(--laburo-green);
      }




      @media(max-width:750px){

        .job-des-html{
            padding-left: 10px;
        }
        .search-container{
            flex-direction: column;
        }
      }

