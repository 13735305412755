:root{
    --laburo-green : rgb(21, 188, 127);
}

.postjob-container{
    width: 100%;
  max-width: 800px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.postjob-gray-container{
display: flex;
flex-direction: column;

width: 100%;
background-color:  rgba(80, 80, 80, 0.1);
/* border: 1px solid rgba(0, 0, 0, 0.2); */
border-radius: 10px;
padding: 20px 10px;
padding-bottom: 25px;

height: 100%;
min-height: 100%;

}

.postjob-top-pill{
    width: 100%;
    max-width: 800px;
    height: 200px;
    padding: 0px 10px;
    background-color: rgba(80, 80, 80, 0.1);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 20px;
}

.postjob-top-pill-container{
   gap: 15px;

}

.postjob-top-pill-step{
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 width: 100%;
}


.validate-job-label {
    font-weight: bold;
    color: black;
    font-size: 1.1em;
    margin-right: 5px;
  }

.progress-bar {
width: 100%;
height: 10px;
border-radius: 10px;
background-color: white;
}

.filled-bar{
    background-color: rgba(255, 165, 0, 0.8);
}


.postjob-step-number{
 text-align: left;
 width: 100%;
 font-size: 15px;
 color: rgba(0,0,0,0.4);
}

.postjob-step-name{
 margin-top: 10px;
}

.postjob-top-pill-title{


width: 100%;
font-size: 28px ;


}

.postjob-container-title{
width: 100%;

font-size: 24px;
margin-bottom: 20px;
}

.postjob-upload-logo-container{
    display: flex;
    margin: 30px 0px 20px;
    justify-content: center;
    gap: 10%;
    align-items: center;
}

.postjob-upload-button-button{
    border: 2px solid rgba(0, 0, 0, 0.8);
    padding: 10px 20px;
    border-radius: 5px;
   width: 200px;
    text-align: center;
}
.postjob-upload-button-button:hover{
    opacity: 0.4;
}

.postjob-createjob-button{
    background-color: var(--laburo-green);
color: black;
   display: flex;
   align-items: center;
   justify-content: center;
   padding: 10px 20px;
   border-radius: 10px;
   height: 60px;
   width: 50%;
   font-size: 20px;
  
  
}

.postjob-createjob-button:hover{
    opacity: 0.4;
}

/* True or False  */
.tof-title{
    font-size: 18px;
    margin-bottom: 10px;
}
.tof-container{
 width: 100%;
 display: flex;
 flex-direction: column;
gap: 5px;
margin-bottom: 20px;
}

.tof-question-box{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  
}


.tof-question-option{
    display: flex;
    gap: 10px;
    align-items: center;
}
.tof-question-dot{
    border-radius: 100px;
    background-color: white;
    height: 18px;
    width: 18px;
  
}
.dot-selected{
background-color: var(--laburo-green);
}

.tof-dot-true {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:var(--laburo-green);
    border-radius: 50px;
    height: 18px;
    width: 18px;
    
}

.postjob-default-logo{
    background-color: var(--laburo-green);
    border-radius: 50px;
    width: 60px;
    height: 60px; 
    display: flex;
    align-items: center;
    justify-content: center;
    

}


.postjob-logo-container{

    width: 60px;
    height: 60px;
    border-radius: 50px;
    overflow: hidden;
}


.postjob-logo-image{

    height: 100%;
    width: 100%;
    object-fit: cover;
}

/* validate Job */

.validate-job-title{
width: 100%;
font-size: 24px;
margin-bottom: 15px;

}

.validate-job-text{
    width: 100%;

}







/* Payment Page */


/* image  */

.stripe-img{
    height: 150px;
}
.checkout-button{
    background-color: var(--laburo-green);
    border-radius: 10px;
    height: 50px;
   
}


.postjob-custom-questions-container-row-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.postjob-custom-questions-container-row{

display: flex;
/* height: 40px; */
width: 100%;

gap: 10px;
}
.postjob-custom-questions-container-row-question{
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    padding: 10px;
    width: 100%;
    font-size: 14px;
}

.postjob-custom-questions-container-row-select-wrapper {

  position: relative;
  width: 100%;
  display: flex;
  max-width: 180px;
  
  /* max-width: 130px; */
}

.postjob-custom-questions-container-row-select {
width: 100%;
  /* max-width: 130px; */
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  padding: 10px 10px;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
}

.postjob-custom-questions-container-row-select-options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-top: none;
  border-radius: 0 0 5px 5px;
  z-index: 10;
}

.postjob-custom-questions-container-row-select-option {
  padding: 10px 5px;
  cursor: pointer;
  font-size: 14px;
}

.postjob-custom-questions-container-row-select-option:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.postjob-custom-questions-container-row-delete{
    width: 10%;
}


.postjob-custom-questions-container-row-options{

display: flex;
flex-wrap: wrap;
gap: 5px;

}


.postjob-custom-questions-container-row-options-input{
    height: 30px;
    border-radius: 5px;
    border:rgba(0, 0, 0, 0.2) 1px solid;
    padding: 0px 5px;

}

.custom-questions-options-button:hover{
    opacity: 0.4;
    cursor: pointer;
}




.validate-job-custom-question{


padding: 0px 10px;

}


.validate-job-custom-question ul{
    padding: 0px 30px;
}



.text-input-post-jobs{

text-align: left;

}
.currency-input-container{
    display: flex;
    align-items: center;
    padding-left: 5px;
    width: 100%;
    

}
.currency-input{
    padding-left: 10px!important;
padding-right: 10px!important;
}

.currency-symbol{
    position: absolute;

}


.locationMapSelector{
display: flex;
top: 0;
left: 0;
flex-direction: column;
    padding: "20px 0px";
    gap: "20px", 
}


  .bottom-button-container:hover{
    
    cursor: pointer;
  }
  .locationMapSelector-content {
    display: flex;
    flex-direction: column;
    
    gap: 20px;}

@media (max-width: 750px) {
   .postjob-custom-questions-container-row{
    flex-direction: column;
   }
   .custom-questions-options-button:hover{
    opacity: 1;
   }

   .añadir-pregunta-button:hover{
    opacity: 1;
   }


.locationMapSelector{

/* background-color: white; */
 /* position: fixed; */
    width: 100%;
    height: 100%;
    /* background-color:white; */
    /* z-index: 1000; */
    display: flex;
    
/* padding: 20px 10px !important; */
margin-bottom: 40px;

}
.locationMapSelector-content {
   
    width: 100%;
   
    gap: 20px; 
    
    flex-direction: column;
        display: flex;
    justify-content: space-evenly;
 
left: 0;
top:0;
  /* padding: 20px 10px; */
}
}

.edit-map-button {
    background-color: var(--laburo-green);
    border: none;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 10px 0;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .edit-map-button:hover {
    opacity: 0.4;
  }
  
  @media (max-width: 750px) {
    .edit-map-button {
      width: 100%;
    }
  }




    /*  experienc and education  */
    .experience_education-add-button{
        display: flex;
        justify-content: space-between;
        padding-right: 10px;
        
        
        }
        
        .ex-ed-add-button{
            display: flex;
            align-items: center;
            gap: 3px;
            border: 1px solid var(--laburo-green)   ;
            padding: 5px 10px;
            border-radius: 5px;
           background-color:var(--laburo-green);
           color: white;
        }
        .ex-ed-add-button:hover{
         
            cursor: pointer;
        }
        .date-picker-placeholder {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 56px; /* Adjust this to match the height of your DatePicker */
            padding: 0 14px;
            border: 1px solid rgba(0, 0, 0, 0.23);
            border-radius: 4px;
            font-size: 1rem;
            line-height: 1.4375em;
            background-color: #f5f5f5;
            color: rgba(0, 0, 0, 0.87);
           
            
    }