/* Target the actual editable content */
.ProseMirror {
  font-family: 'Inter', sans-serif !important;
  font-size: 16px !important;
  line-height: 1.5 !important;
}

/* Basic editor styles */
.tiptap:first-child {
    margin-top: 0;
  }
  
  /* List styles */
  .tiptap ul, 
  .tiptap ol {
    padding: 0 1rem;
    margin: 1.25rem 1rem 1.25rem 0.4rem;
  }
  
  .tiptap ul li p, 
  .tiptap ol li p {
    margin-top: 0.25em;
    margin-bottom: 0.25em;
  }
  
  /* Heading styles */
  .tiptap h1, 
  .tiptap h2, 
  .tiptap h3, 
  .tiptap h4, 
  .tiptap h5, 
  .tiptap h6 {
    line-height: 1.1;
    margin-top: 2.5rem;
    text-wrap: pretty;
  }
  
  .tiptap h1, 
  .tiptap h2 {
    margin-top: 3.5rem;
    margin-bottom: 1.5rem;
  }
  
  .tiptap h1 {
    font-size: 1.4rem;
  }
  
  .tiptap h2 {
    font-size: 1.2rem;
  }
  
  .tiptap h3 {
    font-size: 1.1rem;
  }
  
  .tiptap h4, 
  .tiptap h5, 
  .tiptap h6 {
    font-size: 1rem;
  }
  
  /* Code and preformatted text styles */
  .tiptap code {
    background-color: var(--purple-light);
    border-radius: 0.4rem;
    color: var(--black);
    font-size: 0.85rem;
    padding: 0.25em 0.3em;
  }
  
  .tiptap pre {
    background: var(--black);
    border-radius: 0.5rem;
    color: var(--white);
    font-family: 'JetBrainsMono', monospace;
    margin: 1.5rem 0;
    padding: 0.75rem 1rem;
  }
  
  .tiptap pre code {
    background: none;
    color: inherit;
    font-size: 0.8rem;
    padding: 0;
  }
  
  .tiptap mark {
    background-color: #FAF594;
    border-radius: 0.4rem;
    box-decoration-break: clone;
    padding: 0.1rem 0.3rem;
  }
  
  .tiptap blockquote {
    border-left: 3px solid var(--gray-3);
    margin: 1.5rem 0;
    padding-left: 1rem;
  }
  
  .tiptap hr {
    border: none;
   
    margin: 2rem 0;
  }
  
  /* Add these styles at the appropriate location */

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.label {
  font-size: 18px;
}

.control-group {
  background-color: #f8f9fa;
  padding: 0.5rem;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-bottom: none;
}

.button-group {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.button-group button {
  border: 1px solid var(--gray-3);
  background: white;
  color: black;
  border-radius: 4px;
  padding: 0.4rem 0.8rem;
  cursor: pointer;
}

.button-group button.is-active {
  background: #333;
  color: white;
}

.tiptap {
  padding: 1rem;
  min-height: 150px;
  outline: none;

}

.text-editor-content {
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  height: 400px;
  overflow: auto;
}



.prose h1, .prose h2, .prose h3 {
  margin-top: 10px !important;      /* Reduce from default 2em */
  margin-bottom: 5px !important;
}