.signinContainer{
min-height: 100vh;
width: 100%;
display: flex;
justify-content: center;
align-items: center;
}

.signinContainer__box{
    width: 100%;
    max-width: 400px;
    padding: 2.5rem 1.5rem;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    background-color: white;
}

.signinContainer__box__inner{
    display: flex;
    flex-direction: column;
    justify-content: space-between ;
    height: 100%;
    gap: 20px;
}

.signinContainer__box__form{
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.signinContainer__box__form input{
    height: 50px;
    font-size: 1rem;
    padding: 0 1rem;
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    transition: border-color 0.2s ease;
}

.signinContainer__box__form input:focus {
    outline: none;
    border-color: var(--laburo-green);
    box-shadow: 0 0 0 2px rgba(var(--laburo-green-rgb), 0.1);
}

.signinContainer__box__form button{
    height: 50px;
    font-size: 1.125rem;
    background-color: var(--laburo-green);
    color: white;
    border-radius: 6px;
    border: none;
    transition: all 0.2s ease;
}

.signinContainer__box__form button:hover{
    cursor: pointer;
    opacity: 0.9;
    transform: translateY(-1px);
}

.signupContainer__box__google{
    width: 100%;
    display: flex;
    justify-content: center;
}

.signupContainer_button_google{
    background-color: white !important;
    color: #3c4043;
    border: 1px solid #dadce0;
    border-radius: 8px;
    padding: 0.75rem 1.5rem;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    box-shadow: 0 1px 2px rgba(0,0,0,0.1);
    width: 100%;
    justify-content: center;
}

.signupContainer_button_google:hover {
    background-color: #f8f9fa;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transform: translateY(-1px);
}

.signupContainer_google{
    background-color: white;
    width: 100%;
}

.google-txt{
    /* Stroke color */
    stroke-width: 1px; /* Stroke width */
    stroke-linecap: inside; /* This property doesn't exist in CSS, you may want to ignore or use appropriate property */
    color: #1F1F1F; /* Text color */
    font-family: 'Roboto', sans-serif; /* Font family */
    font-weight: 500; /* Medium corresponds to a font-weight of 500 */
    font-size: 14px; /* Font size */
    line-height: 20px;
}

.roboto-medium {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    font-style: normal;
  }

.recover-password-container{
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    padding : 0px 20px;
    padding-top: 50px;
}

.recover-password{
    border: 1px solid rgba(0, 0, 0, 0.2);
    height: 100%;
    padding: 20px;
    border-radius: 5px;
    max-width: 400px;
}

.recover-password-button{
    height: 40px;
    font-size: 14px;
    background-color: var(--laburo-green);
    color: white;
    border-radius: 5px;
}

.recover-password-button:hover{
    cursor: pointer;
    opacity: 0.6;
}